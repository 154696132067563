<template>
  <div class="enquiry-dashboard">
    <v-row>
      <v-col md="12">
        <v-card
          flat
          class="py-3 px-3"
          style="
            background-image: url(/media/bg/dots-structure-vector.jpg);
            background-size: cover;
            background-position: bottom;
          "
        >
          <v-card-text>
            <div class="d-flex align-center pb-3">
              <div class="text-h5 font-weight-bold text-uppercase">
                Quotation
              </div>
              <v-spacer />
              <!-- <v-btn
                outlined
                color="blue"
                class="text-right rounded custom-menu-select py-1 btn-shadow-hover me-3"
              >
                <v-select
                  :items="categoryList"
                  v-model="isCategory"
                  hide-details
                  :disabled="issueCountLoadingNew"
                  item-color="cyan"
                  class="pt-0 mt-0"
                  v-on:change="getIssueGraphNew"
                >
                </v-select>
              </v-btn> -->
            </div>
            <div class="d-flex align-center justify-space-between">
              <div cols="">
                <div class="d-inline-block text-center">
                  <div class="text-h6 fw-500 mb-2 grey--text">
                    All Quotations
                  </div>
                  <div class="text-h3 font-weight-bold cyan--text mt-1">88</div>
                </div>
              </div>
              <div cols="">
                <div class="d-inline-block text-center">
                  <div class="text-h6 fw-500 mb-2 grey--text">Draft</div>
                  <div class="text-h3 font-weight-bold cyan--text mt-1">43</div>
                </div>
              </div>
              <div cols="">
                <div class="d-inline-block text-center">
                  <div class="text-h6 fw-500 mb-2 grey--text">Accepted</div>
                  <div class="text-h3 font-weight-bold success--text mt-1">
                    4
                  </div>
                </div>
              </div>
              <div cols="">
                <div class="d-inline-block text-center">
                  <div class="text-h6 fw-500 mb-2 grey--text">
                    Pending For Approval
                  </div>
                  <div class="text-h3 font-weight-bold green--text mt-1">
                    12
                  </div>
                </div>
              </div>
              <div cols="">
                <div class="d-inline-block text-center">
                  <div class="text-h6 fw-500 mb-2 grey--text">Unapproval</div>
                  <div class="text-h3 font-weight-bold green--text mt-1">
                    12
                  </div>
                </div>
              </div>
              <div cols="">
                <div class="d-inline-block text-center">
                  <div class="text-h6 fw-500 mb-2 grey--text">Approved</div>
                  <div class="text-h3 font-weight-bold green--text mt-1">
                    12
                  </div>
                </div>
              </div>
              <div cols="">
                <div class="d-inline-block text-center">
                  <div class="text-h6 fw-500 mb-2 grey--text">Rejected</div>
                  <div class="text-h3 font-weight-bold red--text mt-1">1</div>
                </div>
              </div>
              <div cols="">
                <div class="d-inline-block text-center">
                  <div class="text-h6 fw-500 mb-2 grey--text">Sent</div>
                  <div class="text-h3 font-weight-bold orange--text mt-1">
                    4
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="12">
        <v-card flat class="px-3">
          <v-card-text>
            <div class="d-flex align-center">
              <div class="text-h5 font-weight-bold text-uppercase pt-4">
                New & Closed /rejected Value for 6 months
              </div>
              <v-spacer />
              <v-btn
                outlined
                color="blue"
                class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
              >
                <v-select
                  :items="durationList"
                  v-model="isDuration"
                  hide-details
                  :disabled="issueCountLoadingNew"
                  item-color="cyan"
                  class="pt-0 mt-0 black--texr"
                  v-on:change="getIssueGraphNew"
                >
                </v-select>
              </v-btn>
            </div>
            <div id="chart">
              <apexchart
                type="line"
                height="350"
                :options="newClosedJobschartOptions"
                :series="newClosedJobsseries"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="12">
        <v-card
          flat
          class="px-3 py-4"
          style="
            background-image: url(/media/bg/dots-structure-vector.jpg);
            background-size: cover;
            background-position: bottom;
          "
        >
          <v-card-text>
            <div class="d-flex align-center pb-4">
              <div>
                <div class="text-h5 font-weight-bold text-uppercase pb-4">
                  Invoices
                </div>
                <div class="font-size-20">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                </div>
              </div>
              <v-spacer />
              <v-btn
                outlined
                color="blue"
                class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
              >
                <v-select
                  :items="invoicesdurationList"
                  v-model="isInvoicesDuration"
                  hide-details
                  :disabled="issueCountLoadingNew"
                  item-color="cyan"
                  class="pt-0 mt-0 black--texr"
                  v-on:change="getIssueGraphNew"
                >
                </v-select>
              </v-btn>
            </div>
            <div class="invoice">
              <b-progress class="mt-2" height="10rem" :max="max" show-value>
                <b-progress-bar :value="value" variant="cyan"
                  ><span class="text-h4 mb-2"
                    >Draft
                    <v-avatar class="ms-10" color="white"
                      ><span class="font-weight-bold cyan--text"
                        >2</span
                      ></v-avatar
                    ></span
                  >
                  <strong class="text-h4"
                    >${{ value.toFixed(2) }}</strong
                  ></b-progress-bar
                >
                <b-progress-bar :value="value" variant="warning"
                  ><span class="text-h4 mb-2"
                    >Sent:
                    <v-avatar class="ms-10" color="white"
                      ><span class="font-weight-bold warning--text"
                        >2</span
                      ></v-avatar
                    ></span
                  ><strong class="text-h4"
                    >${{ value.toFixed(2) }}</strong
                  ></b-progress-bar
                >
                <b-progress-bar :value="value" variant="warning"
                  ><span class="text-h4 mb-2"
                    >Partially Paid:
                    <v-avatar class="ms-10" color="white"
                      ><span class="font-weight-bold warning--text"
                        >2</span
                      ></v-avatar
                    ></span
                  ><strong class="text-h4"
                    >${{ value.toFixed(2) }}</strong
                  ></b-progress-bar
                >
                <b-progress-bar :value="value" variant="green"
                  ><span class="text-h4 mb-2"
                    >Paid:
                    <v-avatar class="ms-10" color="white"
                      ><span class="font-weight-bold green--text"
                        >2</span
                      ></v-avatar
                    ></span
                  ><strong class="text-h4"
                    >${{ value.toFixed(2) }}</strong
                  ></b-progress-bar
                >

                <b-progress-bar :value="value" variant="danger"
                  ><span class="text-h4 mb-2"
                    >Cancelled:
                    <v-avatar class="ms-10" color="white"
                      ><span class="font-weight-bold red--text"
                        >2</span
                      ></v-avatar
                    ></span
                  ><strong class="text-h4"
                    >${{ value.toFixed(2) }}</strong
                  ></b-progress-bar
                >
              </b-progress>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="12">
        <v-card flat class="px-3">
          <v-card-text>
            <div class="d-flex align-center">
              <div class="text-h5 font-weight-bold text-uppercase pt-4">
                New/Collected/Due Amount for 6 months
              </div>
              <v-spacer />
              <v-btn
                outlined
                color="blue"
                class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
              >
                <v-select
                  :items="durationList"
                  v-model="isDuration"
                  hide-details
                  :disabled="issueCountLoadingNew"
                  item-color="cyan"
                  class="pt-0 mt-0 black--texr"
                  v-on:change="getIssueGraphNew"
                >
                </v-select>
              </v-btn>
            </div>
            <div id="chart">
              <apexchart
                type="line"
                height="350"
                :options="newClosedJobschartOptions"
                :series="newClosedJobsseries"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "ProjectStatus",
  data() {
    return {
      value: 6000,
      max: 100,
      newClosedJobsseries: [
        {
          name: "New ",
          data: [28, 29, 33, 36, 32, 32, 33],
        },
        {
          name: "Closed ",
          data: [12, 11, 14, 18, 17, 13, 13],
        },
      ],
      newClosedJobschartOptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#4CAF50", "#F44336"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Average New & Closed Jobs",
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"],
          title: {
            text: "Month",
          },
        },
        yaxis: {
          title: {
            text: "Jobs",
          },
          min: 5,
          max: 40,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },

      categoryList: [
        { text: "Open", value: "open" },
        { text: "Closed", value: "closed" },
        { text: "Overdue", value: "overdue" },
      ],
      isCategory: "open",
      invoicesdurationList: [
        { text: "This Week", value: "this_week" },
        { text: "This Month", value: "this_month" },
        { text: "Last 3 Month", value: "last-3month" },
        { text: "Last 6 Month", value: "last-6month" },
      ],
      isInvoicesDuration: "this_month",
      durationList: [
        { text: "This Week", value: "this_week" },
        { text: "This Month", value: "this_month" },
        { text: "Last 3 Month", value: "last-3month" },
        { text: "Last 6 Month", value: "last-6month" },
      ],
      isDuration: "this_month",
    };
  },
  methods: {
    randomValue() {
      this.value = Math.random() * this.max;
    },
  },
};
</script>
<style scoped>
.invoice .progress-bar:first-child {
  clip-path: polygon(
    0% 0%,
    calc(100% - 30px) 0%,
    100% 50%,
    calc(100% - 50px) 150%,
    0% 100%,
    calc(0% + 0px) 50%
  );
}
.invoice .progress-bar {
  clip-path: polygon(
    0% 0%,
    calc(100% - 30px) 0%,
    100% 50%,
    calc(100% - 50px) 150%,
    0% 100%,
    calc(0% + 20px) 50%
  );
}
.invoice .progress-bar:last-child {
  clip-path: polygon(
    0% 0%,
    calc(100% + 0px) 0%,
    100% 100%,
    calc(100% - 40px) 150%,
    0% 100%,
    calc(0% + 20px) 50%
  );
}
</style>
