<template>
  <div class="enquiry-dashboard">
    <v-row>
      <v-col md="12">
        <v-card flat class="py-4">
          <v-layout class="dashboard-top-button px-3 overflow-hidden">
            <v-flex
              v-for="(item, index) in enquirySummaryData"
              :key="index"
              class="mx-4"
            >
              <v-card
                outlined
                :color="`${item.color} lighten-5`"
                :style="`border-color: ${getBgColor(item.color)}!important`"
              >
                <v-card-text>
                  <div class="text-h6 fw-500">{{ item.title }}</div>
                  <div class="d-flex align-center">
                    <div
                      :class="`text-h4 font-weight-bold ${item.textColor} mt-1`"
                    >
                      {{ item.value }}
                    </div>
                    <v-spacer />
                    <v-icon :size="40" :color="item.iconColor">
                      {{ item.icon }}
                    </v-icon>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-col>
      <v-col md="4">
        <v-card flat class="px-3" height="100%">
          <v-card-text>
            <div class="align-center">
              <div class="text-h5 font-weight-bold text-uppercase pt-4">
                Unresolved Tickets by Priority
              </div>
              <div class="font-size-20 grey--text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              </div>
              <v-spacer />
            </div>
            <div id="chart">
              <apexchart
                type="donut"
                width="350"
                :options="ticketsByPrioritychartOptions"
                :series="ticketsByPriorityseries"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4">
        <v-card flat class="px-3" height="100%">
          <v-card-text>
            <div class="">
              <div class="text-h5 font-weight-bold text-uppercase pt-4">
                Unresolved Tickets by Status
              </div>
              <div class="font-size-20 grey--text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              </div>
              <v-spacer />
            </div>
            <div id="chart">
              <apexchart
                type="donut"
                width="400"
                :options="ticketsByStatuschartOptions"
                :series="ticketsByStatusseries"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="4">
        <v-card flat class="px-3 pb-2" height="100%">
          <v-card-text>
            <div class="mb-4">
              <div class="text-h5 font-weight-bold text-uppercase pt-4">
                Average Time to Resolve
              </div>
              <div class="font-size-20 grey--text">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit,
              </div>
              <v-spacer />
            </div>
            <v-card
              color="cyan lighten-5"
              flat
              class="d-inline-block me-2 mb-2"
              v-for="n in 1"
              :key="n"
            >
              <v-card-text>
                <div
                  class="text-h5 cyan--text font-weight-bold mt-1 text-center"
                >
                  <v-icon color="cyan" size="50" class="d-block mb-2"
                    >mdi-timetable</v-icon
                  >
                  10 Days, 4 Hrs,<br />
                  50 Mins
                </div>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="12">
        <v-card flat class="px-4">
          <v-card-text>
            <div class="d-flex align-center">
              <div class="text-h5 font-weight-bold text-uppercase pt-4">
                New & Open Tickets Category-wise
              </div>
              <v-spacer />
              <v-btn
                outlined
                color="blue"
                class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
              >
                <v-select
                  :items="durationList"
                  v-model="isDuration"
                  hide-details
                  :disabled="issueCountLoadingNew"
                  item-color="cyan"
                  class="pt-0 mt-0 black--texr"
                  v-on:change="getIssueGraphNew"
                >
                </v-select>
              </v-btn>
            </div>
            <div id="chart">
              <apexchart
                type="bar"
                height="350"
                :options="categoryTicketschartOptions"
                :series="categoryTicketsseries"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="12">
        <v-card flat class="px-3">
          <v-card-text>
            <div class="d-flex align-center">
              <div class="text-h5 font-weight-bold text-uppercase pt-4">
                New & Closed Jobs for 6 months
              </div>
              <v-spacer />
              <v-btn
                outlined
                color="blue"
                class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
              >
                <v-select
                  :items="durationList"
                  v-model="isDuration"
                  hide-details
                  :disabled="issueCountLoadingNew"
                  item-color="cyan"
                  class="pt-0 mt-0 black--texr"
                  v-on:change="getIssueGraphNew"
                >
                </v-select>
              </v-btn>
            </div>
            <div id="chart">
              <apexchart
                type="line"
                height="350"
                :options="newClosedJobschartOptions"
                :series="newClosedJobsseries"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "ProjectStatus",
  data() {
    return {
      enquirySummaryData: [
        {
          title: "Open",
          value: 670,
          color: "indigo",
          textColor: "indigo--text",
          iconColor: "indigo",
          icon: "mdi-account-question-outline",
        },
        {
          title: "In Progress",
          value: 36,
          color: "yellow",
          textColor: "yellow--text",
          iconColor: "yellow",
          icon: "mdi-account-question-outline",
        },
        {
          title: "On Hold",
          value: 16,
          color: "orange",
          textColor: "orange--text",
          iconColor: "orange",
          icon: "mdi-account-question-outline",
        },
        {
          title: "Completed",
          value: 14,
          color: "green",
          textColor: "green--text",
          iconColor: "green",
          icon: "mdi-account-question-outline",
        },
        {
          title: "Cancelled",
          value: 2,
          color: "red",
          textColor: "red--text",
          iconColor: "red",
          icon: "mdi-account-question-outline",
        },
        {
          title: "Closed",
          value: 2,
          color: "red",
          textColor: "red--text",
          iconColor: "red",
          icon: "mdi-account-question-outline",
        },
      ],
      newClosedJobsseries: [
        {
          name: "New Jobs",
          data: [28, 29, 33, 36, 32, 32],
        },
        {
          name: "Closed Jobs",
          data: [12, 11, 14, 18, 17, 13],
        },
      ],
      newClosedJobschartOptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#4CAF50", "#F44336"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Average New & Closed Jobs",
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
          title: {
            text: "Month",
          },
        },
        yaxis: {
          title: {
            text: "Jobs",
          },
          min: 5,
          max: 40,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },
      ticketsByPriorityseries: [43, 12, 15],
      ticketsByPrioritychartOptions: {
        chart: {
          width: 425,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        labels: ["Low", "Medium", "High"],
        dataLabels: {
          enabled: false,
        },

        fill: {
          type: "gradient",
        },
        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
                position: "bottom",
              },
            },
          },
        ],
      },
      ticketsByStatusseries: [43, 12, 1, 4, 1, 1],
      ticketsByStatuschartOptions: {
        chart: {
          width: 480,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        labels: [
          "Open",
          "In-Progress",
          "Hold & Stay Assign",
          "Completed",
          "Cancelled",
          "Closed",
        ],
        dataLabels: {
          enabled: false,
        },
        fill: {
          type: "gradient",
        },
        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                show: false,
                position: "bottom",
              },
            },
          },
        ],
      },
      categoryTicketsseries: [
        {
          name: "New",
          data: [44, 55, 41, 37],
        },
        {
          name: "Open",
          data: [53, 32, 33, 52],
        },
        {
          name: "Closed",
          data: [12, 17, 11, 9],
        },
        {
          name: "Visit Raise",
          data: [9, 7, 5, 8],
        },
      ],
      categoryTicketschartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        title: {
          text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
          align: "left",
        },
        xaxis: {
          categories: ["New", "Open", "Closed", "Visit Raise"],
          labels: {
            formatter: function (val) {
              return val + "";
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "Tickets";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
      categoryList: [
        { text: "Open", value: "open" },
        { text: "Closed", value: "closed" },
        { text: "Overdue", value: "overdue" },
      ],
      isCategory: "open",
      durationList: [
        { text: "This Week", value: "this_week" },
        { text: "This Month", value: "this_month" },
        { text: "Last 3 Month", value: "last-3month" },
        { text: "Last 6 Month", value: "last-6month" },
      ],
      isDuration: "this_month",
      network: 63,
      software: 46,
      other: 43,
      hardware: 33,
    };
  },
  methods: {
    getBgColor(color) {
      switch (color) {
        case "cyan":
          return "#00bcd4";
        case "green":
          return "#4CAF50";
        case "red":
          return "#F44336";
        case "blue":
          return "#ff9800";
        case "orange":
          return "#ff9800";
        case "yellow":
          return "#ffeb3b";
        case "indigo":
          return "#3f51b5";
        default:
          return "white";
      }
    },
  },
};
</script>
