var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"border",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"orange lighten-4"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-chart-multiple")]),_c('div',{staticClass:"my-auto fw-600 text-uppercase fs-18"},[_vm._v(" Technicians Complete Visits :- "),_c('span',{staticStyle:{"color":"#24326d !important"}},[_vm._v("("+_vm._s(_vm.dActiveProjectUser.display_name)+")")])]),_c('v-spacer'),_c('v-menu',{attrs:{"left":"","bottom":"","offset-y":"","max-width":200,"max-height":300,"content-class":"z-index-11"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-5",attrs:{"height":"35","min-height":"35","color":"blue lighten-5","depressed":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"fs-16 text-capitalize text-truncate",staticStyle:{"width":"140px"}},[_vm._v(_vm._s(_vm.dActiveProjectUser.display_name))]),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.engineersList),function(item,index){return _c('v-list-item',{key:index,class:[
              'py-1',
              {
                'grey lighten-3':
                  JSON.stringify(_vm.dActiveProjectUser) == JSON.stringify(item),
              },
            ],on:{"click":function($event){return _vm.filterByUser(item)}}},[_c('v-list-item-avatar',{staticClass:"my-1 mr-2",attrs:{"size":"30"}},[_c('v-img',{attrs:{"src":item.profile_img
                    ? item.profile_img
                    : _vm.$assetURL(`media/users/blank.png`)}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticStyle:{"min-width":"auto"}},[_vm._v(_vm._s(item.display_name))]),(item.complate_visits)?_c('v-list-item-subtitle',{staticClass:"mb-1 text-uppercase"},[_c('span',{staticClass:"fw-700"},[_vm._v(_vm._s(item.complate_visits))]),_vm._v(" Visits")]):_vm._e()],1)],1)}),1)],1),_c('v-menu',{attrs:{"left":"","offset-y":"","max-width":200,"content-class":"z-index-11"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"height":"35","min-height":"35","color":"orange lighten-5","depressed":""}},'v-btn',attrs,false),on),[_c('span',{staticClass:"fs-16 text-capitalize px-3"},[_vm._v(_vm._s(_vm.dActiveProjectDuration.text))]),_c('v-icon',[_vm._v("mdi-menu-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.projectDurationFilterList),function(item,index){return _c('v-list-item',{key:index,class:[
              'py-1',
              {
                'grey lighten-3': _vm.dActiveProjectDuration?.value == item.value,
              },
            ],on:{"click":function($event){return _vm.filterByDuration(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(item.text))])],1)}),1)],1)],1),(false)?_c('v-layout',{staticClass:"px-4",staticStyle:{"margin-top":"-50px"}},[_c('v-flex',{staticClass:"border py-2 px-5 mr-5 blue lighten-4 blue--text rounded position-relative overflow-hidden"},[_c('div',{staticClass:"fw-600",staticStyle:{"font-size":"2.5rem"}},[(_vm.pageLoading)?[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"24","width":"2","color":"blue"}})]:void 0],2),_c('v-icon',{staticClass:"d-summery-icon",attrs:{"color":"blue","size":"5rem"}},[_vm._v("mdi-chart-bell-curve")]),_c('div',{staticClass:"font-size-h6"},[_vm._v("New Projects")])],1),_c('v-flex',{staticClass:"border py-2 px-5 mr-5 green lighten-4 green--text rounded position-relative overflow-hidden"},[_c('div',{staticClass:"fw-600",staticStyle:{"font-size":"2.5rem"}},[(_vm.pageLoading)?[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"24","width":"2","color":"blue"}})]:[_vm._v(" "+_vm._s(_vm.getTotal.new)+" ")]],2),_c('v-icon',{staticClass:"d-summery-icon",attrs:{"color":"green","size":"5rem"}},[_vm._v("mdi-chart-bell-curve")]),_c('div',{staticClass:"font-size-h6"},[_vm._v("Complete Visits")])],1),_c('v-flex',{staticClass:"border py-2 px-5 red lighten-4 red--text rounded position-relative overflow-hidden"},[_c('div',{staticClass:"fw-600",staticStyle:{"font-size":"2.5rem"}},[(_vm.pageLoading)?[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"24","width":"2","color":"red"}})]:void 0],2),_c('v-icon',{staticClass:"d-summery-icon",attrs:{"color":"red","size":"5rem"}},[_vm._v("mdi-chart-bell-curve")]),_c('div',{staticClass:"font-size-h6"},[_vm._v("Cancelled Project")])],1)],1):_vm._e(),_c('div',{staticClass:"mx-auto px-4 py-3"},[_c('div',[_c('apexchart',{attrs:{"type":"line","height":"450","options":_vm.getChartOptions,"series":_vm.getSeries,"width":"100%"}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }