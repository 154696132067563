<template>
  <v-container fluid class="custom-bthrust-dashboard dashboard-new pt-0">
    <v-row>
      <v-col md="12">
        <v-card
          class="pa-3 mb-2"
          flat
          elevation="0"
          style="position: sticky; top: 50px; z-index: 99"
        >
          <v-tabs
            v-model="dashboardTab"
            background-color="transparent"
            centered
            color="cyan"
            fixed-tabs
            grow
            class="custom-tab-transparent"
            hide-slider
          >
            <template v-for="(tab, index) in tabs">
              <v-tab
                class="font-size-16 font-weight-600 px-8"
                :key="index"
                :href="'#tab-' + tab.key"
              >
                <v-icon color="grey darken-4" left>{{ tab.icon }}</v-icon>
                {{ tab.title }}
              </v-tab>
            </template>
          </v-tabs>
        </v-card>
        <v-tabs-items
          v-model="dashboardTab"
          style="background-color: transparent !important"
        >
          <v-tab-item :value="'tab-jobs'">
            <v-row>
              <v-col md="12">
                <v-card flat class="py-4">
                  <v-layout class="dashboard-top-button px-3 overflow-hidden">
                    <v-flex
                      v-for="(item, index) in jobSummaryData"
                      :key="index"
                      class="mx-4"
                    >
                      <v-card
                        outlined
                        :color="`${item.color} lighten-5`"
                        :style="`border-color: ${getBgColor(
                          item.color
                        )}!important`"
                      >
                        <v-card-text>
                          <div class="text-h6 fw-500">{{ item.title }}</div>
                          <div class="d-flex align-center">
                            <div
                              :class="`text-h4 font-weight-bold ${item.textColor} mt-1`"
                            >
                              {{ item.value }}
                            </div>
                            <v-spacer />
                            <v-icon :size="40" :color="item.iconColor">
                              {{ item.icon }}
                            </v-icon>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-col>
              <v-col md="12">
                <v-card
                  flat
                  class="py-3 px-3"
                  style="
                    background-image: url(/media/bg/dots-structure-vector.jpg);
                    background-size: cover;
                    background-position: bottom;
                  "
                >
                  <v-card-text>
                    <div class="d-flex align-center pb-3">
                      <div class="text-h5 font-weight-bold text-uppercase">
                        Job Status
                      </div>
                      <v-spacer />
                      <v-btn
                        outlined
                        color="blue"
                        class="text-right rounded custom-menu-select py-1 btn-shadow-hover me-3"
                      >
                        <v-select
                          :items="categoryList"
                          v-model="isCategory"
                          hide-details
                          :disabled="issueCountLoadingNew"
                          item-color="cyan"
                          class="pt-0 mt-0"
                          v-on:change="getIssueGraphNew"
                        >
                        </v-select>
                      </v-btn>
                      <v-icon class="grey--text"
                        >mdi-information-outline</v-icon
                      >
                    </div>
                    <v-row>
                      <v-col cols="2">
                        <div class="d-inline-block text-center">
                          <div class="text-h6 fw-500 mb-2 grey--text">
                            Total Jobs
                          </div>
                          <div class="text-h3 font-weight-bold blue--text mt-1">
                            61
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div class="d-inline-block text-center">
                          <div class="text-h6 fw-500 mb-2 grey--text">Open</div>
                          <div class="text-h3 font-weight-bold cyan--text mt-1">
                            43
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div class="d-inline-block text-center">
                          <div class="text-h6 fw-500 mb-2 grey--text">
                            In-Progress
                          </div>
                          <div
                            class="text-h3 font-weight-bold green--text mt-1"
                          >
                            12
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div class="d-inline-block text-center">
                          <div class="text-h6 fw-500 mb-2 grey--text">
                            Cancelled
                          </div>
                          <div class="text-h3 font-weight-bold red--text mt-1">
                            1
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div class="d-inline-block text-center">
                          <div class="text-h6 fw-500 mb-2 grey--text">
                            Hold & Stay Assign
                          </div>
                          <div
                            class="text-h3 font-weight-bold orange--text mt-1"
                          >
                            1
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="2">
                        <div class="d-inline-block text-center">
                          <div class="text-h6 fw-500 mb-2 grey--text">
                            Completed
                          </div>
                          <div
                            class="text-h3 font-weight-bold success--text mt-1"
                          >
                            4
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="4">
                <v-card flat class="px-3" height="100%">
                  <v-card-text>
                    <div class="d-flex align-center">
                      <div class="text-h5 font-weight-bold text-uppercase pt-4">
                        Jobs by Category
                        <v-chip class="ma-2 white--text" color="green">
                          Top 5
                        </v-chip>
                      </div>
                      <v-spacer />
                      <v-btn
                        outlined
                        color="blue"
                        class="text-right custom-menu-select py-1 rounded btn-shadow-hover me-3"
                      >
                        <v-select
                          :items="categoryList"
                          v-model="isCategory"
                          hide-details
                          :disabled="issueCountLoadingNew"
                          item-color="cyan"
                          class="pt-0 mt-0"
                          v-on:change="getIssueGraphNew"
                        >
                        </v-select>
                      </v-btn>
                      <!-- <v-btn rounded plain icon
                        ><v-icon>mdi-fullscreen-exit</v-icon></v-btn
                      > -->
                    </div>
                    <div id="chart">
                      <apexchart
                        type="bar"
                        height="220"
                        :options="jobCategorychartOptions"
                        :series="jobCategoryseries"
                      ></apexchart>
                    </div>
                    <div class="blue--text font-size-16">
                      View Report(Open Jobs by Category)
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="4">
                <v-card flat class="px-3" height="100%">
                  <v-card-text>
                    <div class="d-flex align-center">
                      <div class="text-h5 font-weight-bold text-uppercase py-4">
                        Jobs by Status
                      </div>
                      <v-spacer />
                      <v-btn
                        outlined
                        color="blue"
                        class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
                      >
                        <v-select
                          :items="categoryList"
                          v-model="isCategory"
                          hide-details
                          :disabled="issueCountLoadingNew"
                          item-color="cyan"
                          class="pt-0 mt-0 black--texr"
                          v-on:change="getIssueGraphNew"
                        >
                        </v-select>
                      </v-btn>
                    </div>
                    <div id="chart">
                      <apexchart
                        type="donut"
                        width="425"
                        :options="jobStatuschartOptions"
                        :series="jobStatusseries"
                      ></apexchart>
                    </div>
                    <div class="blue--text font-size-16">
                      View Report(Open Jobs by Region)
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="4">
                <v-card flat height="100%">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="py-0 text-end">
                        <v-btn
                          outlined
                          color="blue"
                          class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
                        >
                          <v-select
                            :items="categoryList"
                            v-model="isCategory"
                            hide-details
                            :disabled="issueCountLoadingNew"
                            item-color="cyan"
                            class="pt-0 mt-0 black--texr"
                            v-on:change="getIssueGraphNew"
                          >
                          </v-select> </v-btn
                      ></v-col>
                      <v-col cols="6" class="py-0">
                        <div class="card-custom dash_card">
                          <div class="card-body p-0">
                            <div
                              class="cardtopbar justify-content-between pt-4 pb-3"
                            >
                              <h4>VISITS DUE TIMES</h4>
                            </div>
                            <div
                              id="kt_stats_widget_11_chart"
                              class="card-rounded-bottom mid_part"
                              data-color="danger"
                            >
                              <div
                                class="dash_bottom d-flex align-items-center justify-content-between pb-2 flex-grow-1"
                              >
                                <div
                                  class="d-flex flex-column text-left v_list"
                                >
                                  <ul>
                                    <li>
                                      <div>
                                        <span
                                          class="svg-icon svg-icon-xl svg-icon-primary"
                                        >
                                          <inline-svg
                                            :src="
                                              $assetURL(
                                                'media/custom-svg/right.svg'
                                              )
                                            "
                                          />
                                        </span>
                                        <strong>In-Progress</strong>
                                      </div>
                                      <div class="vdnum" v-if="countLoading">
                                        <v-progress-circular
                                          indeterminate
                                          size="24"
                                          color="#3a6afd"
                                        ></v-progress-circular>
                                      </div>
                                      <span
                                        v-else
                                        style="
                                          background-color: green !important;
                                        "
                                      >
                                        {{ visitCount.total_inprogress_visits }}
                                      </span>
                                    </li>
                                    <li>
                                      <div>
                                        <span
                                          class="svg-icon svg-icon-xl svg-icon-primary"
                                        >
                                          <inline-svg
                                            :src="
                                              $assetURL(
                                                'media/custom-svg/right.svg'
                                              )
                                            "
                                          />
                                        </span>
                                        <strong>Today</strong>
                                      </div>
                                      <div class="vdnum" v-if="countLoading">
                                        <v-progress-circular
                                          indeterminate
                                          size="24"
                                          color="#3a6afd"
                                        ></v-progress-circular>
                                      </div>
                                      <span
                                        v-else
                                        style="
                                          background-color: blue !important;
                                        "
                                      >
                                        {{ visitCount.total_today_visits }}
                                      </span>
                                    </li>
                                    <li>
                                      <div>
                                        <span
                                          class="svg-icon svg-icon-xl svg-icon-primary"
                                        >
                                          <inline-svg
                                            :src="
                                              $assetURL(
                                                'media/custom-svg/right.svg'
                                              )
                                            "
                                          />
                                        </span>
                                        <strong>Tomorrow</strong>
                                      </div>
                                      <div class="vdnum" v-if="countLoading">
                                        <v-progress-circular
                                          indeterminate
                                          size="24"
                                          color="#3a6afd"
                                        ></v-progress-circular>
                                      </div>
                                      <span
                                        v-else
                                        style="
                                          background-color: cyan !important;
                                        "
                                      >
                                        {{ visitCount.total_tomorrow_visits }}
                                      </span>
                                    </li>
                                    <li>
                                      <div>
                                        <span
                                          class="svg-icon svg-icon-xl svg-icon-primary"
                                        >
                                          <inline-svg
                                            :src="
                                              $assetURL(
                                                'media/custom-svg/right.svg'
                                              )
                                            "
                                          />
                                        </span>
                                        <strong>This week</strong>
                                      </div>
                                      <div class="vdnum" v-if="countLoading">
                                        <v-progress-circular
                                          indeterminate
                                          size="24"
                                          color="#3a6afd"
                                        ></v-progress-circular>
                                      </div>
                                      <span
                                        v-else
                                        style="
                                          background-color: orange !important;
                                        "
                                      >
                                        {{ visitCount.total_this_week_visits }}
                                      </span>
                                    </li>
                                    <li>
                                      <div>
                                        <span
                                          class="svg-icon svg-icon-xl svg-icon-primary"
                                        >
                                          <inline-svg
                                            :src="
                                              $assetURL(
                                                'media/custom-svg/right.svg'
                                              )
                                            "
                                          />
                                        </span>
                                        <strong>Upcoming</strong>
                                      </div>
                                      <div class="vdnum" v-if="countLoading">
                                        <v-progress-circular
                                          indeterminate
                                          size="24"
                                          color="#3a6afd"
                                        ></v-progress-circular>
                                      </div>
                                      <span v-else>
                                        {{ visitCount.total_upcomming_visits }}
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="6" class="py-0 dash_card">
                        <div class="cardtopbar px-0 justify-content-between">
                          <h4>PRIORITY COUNT</h4>
                        </div>
                        <div
                          id="kt_stats_widget_11_chart"
                          class="card-rounded-bottom mid_part"
                          data-color="danger"
                        >
                          <div
                            class="dash_bottom d-flex align-items-center justify-content-between card-spacerpt-4 px-0 pb-2 flex-grow-1"
                          >
                            <div class="d-flex flex-column text-left v_list">
                              <ul class="px-0 d-flex">
                                <template
                                  v-for="(row, index) in visitPriorityArr"
                                >
                                  <li :key="index">
                                    <div>
                                      <span
                                        :class="`svg-icon svg-icon-xl ${priorityColor(
                                          row
                                        )}`"
                                      >
                                        <inline-svg
                                          :src="
                                            $assetURL(
                                              'media/custom-svg/right.svg'
                                            )
                                          "
                                        />
                                      </span>
                                      <strong>{{ priorityText(row) }}</strong>
                                    </div>
                                    <div class="vdnum" v-if="countLoading">
                                      <v-progress-circular
                                        indeterminate
                                        size="24"
                                        :color="priorityBgColor(row)"
                                      ></v-progress-circular>
                                    </div>

                                    <span
                                      v-else
                                      :style="{
                                        'background-color':
                                          priorityBgColor(row),
                                      }"
                                    >
                                      {{ row.total_visit }}
                                    </span>
                                  </li>
                                </template>
                              </ul>
                              <ul class="px-0">
                                <li v-if="!checkExixsPriority(3)">
                                  <div>
                                    <span
                                      :class="`svg-icon svg-icon-xl svg-icon-success`"
                                    >
                                      <inline-svg
                                        :src="
                                          $assetURL(
                                            'media/custom-svg/right.svg'
                                          )
                                        "
                                      />
                                    </span>
                                    <strong>Low</strong>
                                  </div>
                                  <div class="vdnum" v-if="countLoading">
                                    <v-progress-circular
                                      indeterminate
                                      size="24"
                                      color="#1bc5bd"
                                    ></v-progress-circular>
                                  </div>

                                  <span
                                    v-else
                                    :style="{ 'background-color': '#1bc5bd' }"
                                  >
                                    0
                                  </span>
                                </li>
                                <li v-if="!checkExixsPriority(2)">
                                  <div>
                                    <span
                                      :class="`svg-icon svg-icon-xl svg-icon-warning`"
                                    >
                                      <inline-svg
                                        :src="
                                          $assetURL(
                                            'media/custom-svg/right.svg'
                                          )
                                        "
                                      />
                                    </span>
                                    <strong>Medium</strong>
                                  </div>
                                  <div class="vdnum" v-if="countLoading">
                                    <v-progress-circular
                                      indeterminate
                                      size="24"
                                      color="#ffa800"
                                    ></v-progress-circular>
                                  </div>

                                  <span
                                    v-else
                                    :style="{ 'background-color': '#ffa800' }"
                                  >
                                    0
                                  </span>
                                </li>
                                <li v-if="!checkExixsPriority(1)">
                                  <div>
                                    <span
                                      :class="`svg-icon svg-icon-xl svg-icon-danger`"
                                    >
                                      <inline-svg
                                        :src="
                                          $assetURL(
                                            'media/custom-svg/right.svg'
                                          )
                                        "
                                      />
                                    </span>
                                    <strong>High</strong>
                                  </div>
                                  <div class="vdnum" v-if="countLoading">
                                    <v-progress-circular
                                      indeterminate
                                      size="24"
                                      color="#f64e60"
                                    ></v-progress-circular>
                                  </div>

                                  <span
                                    v-else
                                    :style="{ 'background-color': '#f64e60' }"
                                  >
                                    0
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="6">
                <v-card flat class="px-4">
                  <v-card-text>
                    <div class="d-flex align-center">
                      <div class="text-h5 font-weight-bold text-uppercase py-4">
                        Jobs by Engineer
                        <v-chip class="ma-2 white--text" color="green">
                          Top 10
                        </v-chip>
                      </div>
                      <v-spacer />
                      <v-btn
                        outlined
                        color="blue"
                        class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
                      >
                        <v-select
                          :items="durationList"
                          v-model="customerDuration"
                          hide-details
                          :disabled="issueCountLoadingNew"
                          item-color="cyan"
                          class="pt-0 mt-0 black--texr"
                          v-on:change="getIssueGraphNew"
                        >
                        </v-select>
                      </v-btn>
                    </div>
                    <div id="chart">
                      <apexchart
                        type="bar"
                        height="350"
                        :options="jobByEngineerchartOptions"
                        :series="jobByEngineerseries"
                      ></apexchart>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="6">
                <v-card flat class="px-4">
                  <v-card-text>
                    <div class="d-flex align-center">
                      <div class="text-h5 font-weight-bold text-uppercase py-4">
                        Jobs Category by Engineer
                        <v-chip class="ma-2 white--text" color="green">
                          Top 10
                        </v-chip>
                      </div>
                      <v-spacer />
                      <v-btn
                        outlined
                        color="blue"
                        class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
                      >
                        <v-select
                          :items="durationList"
                          v-model="customerDuration"
                          hide-details
                          :disabled="issueCountLoadingNew"
                          item-color="cyan"
                          class="pt-0 mt-0 black--texr"
                          v-on:change="getIssueGraphNew"
                        >
                        </v-select>
                      </v-btn>
                    </div>
                    <div id="chart">
                      <apexchart
                        type="bar"
                        height="350"
                        :options="jobCategoryByEngineerchartOptions"
                        :series="jobCategoryByEngineerseries"
                      ></apexchart>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="4">
                <v-card flat class="px-3">
                  <v-card-text>
                    <div class="d-flex align-center">
                      <div>
                        <div
                          class="text-h5 font-weight-bold text-uppercase pt-4"
                        >
                          Engineer Profile
                        </div>
                        <div class="font-size-20 grey--text">
                          Lorem ipsum dolor sit amet consectetur
                        </div>
                      </div>
                      <v-spacer />
                      <v-btn
                        outlined
                        color="blue"
                        class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
                      >
                        <v-select
                          :items="engineerdurationList"
                          v-model="engcustomerDuration"
                          hide-details
                          :disabled="issueCountLoadingNew"
                          item-color="cyan"
                          class="pt-0 mt-0 black--texr"
                          v-on:change="getIssueGraphNew"
                        >
                        </v-select>
                      </v-btn>
                    </div>
                    <div class="enginer-profile">
                      <div class="swiper-container">
                        <div class="swiper">
                          <div class="swiper-wrapper">
                            <!-- Slide 1 -->
                            <div class="swiper-slide swiper-slide--one">
                              <div class="slide-content">
                                <v-avatar size="80">
                                  <v-img
                                    src="https://cdn.vuetifyjs.com/images/john.png"
                                  ></v-img>
                                </v-avatar>
                                <h4 class="white--text mt-3">Jane Eyre</h4>
                              </div>
                              <div class="author">
                                <v-chip
                                  style="height: 25px; border: 1px solid #fff"
                                  class="rank white-text"
                                  color="#ffd700"
                                  text-color="white"
                                >
                                  <v-icon color="">mdi-crown</v-icon>
                                  <span class="text-h6">Rank 1</span>
                                </v-chip>
                              </div>
                            </div>

                            <!-- Slide 2 -->
                            <div class="swiper-slide swiper-slide--two">
                              <div class="slide-content">
                                <v-avatar size="80">
                                  <v-img
                                    src="https://cdn.vuetifyjs.com/images/lists/2.jpg"
                                  ></v-img>
                                </v-avatar>
                                <h4 class="white--text mt-3">Jane Eyre</h4>
                              </div>
                              <div class="author">
                                <v-chip
                                  style="height: 25px; border: 1px solid #fff"
                                  class="rank white-black"
                                  color="#C0C0C0"
                                  text-color="white"
                                >
                                  <span class="text-h6">Rank 2</span>
                                </v-chip>
                              </div>
                            </div>

                            <!-- Slide 3 -->
                            <div class="swiper-slide swiper-slide--three">
                              <div class="slide-content">
                                <v-avatar size="80">
                                  <v-img
                                    src="https://cdn.vuetifyjs.com/images/john.png"
                                  ></v-img>
                                </v-avatar>
                                <h4 class="white--text mt-3">Jane Eyre</h4>
                              </div>
                              <div class="author">
                                <v-chip
                                  style="height: 25px; border: 1px solid #fff"
                                  class="rank white-text"
                                  color="#CD7F32"
                                  text-color="white"
                                >
                                  <span class="text-h6">Rank 3</span>
                                </v-chip>
                              </div>
                            </div>

                            <!-- Slide 4 -->
                            <div class="swiper-slide swiper-slide--four">
                              <div class="slide-content">
                                <v-avatar size="80">
                                  <v-img
                                    src="https://cdn.vuetifyjs.com/images/lists/2.jpg"
                                  ></v-img>
                                </v-avatar>
                                <h4 class="white--text mt-3">Jane Eyre</h4>
                              </div>
                              <div class="author">
                                <v-chip
                                  style="height: 25px; border: 1px solid #fff"
                                  class="rank white-black"
                                  color="#C0C0C0"
                                  text-color="white"
                                >
                                  <span class="text-h6">Rank 2</span>
                                </v-chip>
                              </div>
                            </div>

                            <!-- Slide 5 -->
                            <div class="swiper-slide swiper-slide--five">
                              <div class="slide-content">
                                <v-avatar size="80">
                                  <v-img
                                    src="https://cdn.vuetifyjs.com/images/john.png"
                                  ></v-img>
                                </v-avatar>
                                <h4 class="white--text mt-3">Jane Eyre</h4>
                              </div>
                              <div class="author">
                                <v-chip
                                  style="height: 25px; border: 1px solid #fff"
                                  class="rank white-text"
                                  color="#ffd700"
                                  text-color="white"
                                >
                                  <v-icon color="">mdi-crown</v-icon>
                                  <span class="text-h6">Rank 1</span>
                                </v-chip>
                              </div>
                            </div>

                            <!-- Slide 6 -->
                            <div class="swiper-slide swiper-slide--six">
                              <div class="slide-content">
                                <v-avatar size="80">
                                  <v-img
                                    src="https://cdn.vuetifyjs.com/images/lists/2.jpg"
                                  ></v-img>
                                </v-avatar>
                                <h4 class="white--text mt-3">Jane Eyre</h4>
                              </div>
                              <div class="author">
                                <v-chip
                                  style="height: 25px; border: 1px solid #fff"
                                  class="rank white-black"
                                  color="#CD7F32"
                                  text-color="white"
                                >
                                  <span class="text-h6">Rank 3</span>
                                </v-chip>
                              </div>
                            </div>
                          </div>

                          <!-- Swiper Navigation -->
                          <div class="swiper-pagination"></div>
                        </div>
                        <!-- If we need navigation buttons -->
                        <!-- <div class="swiper-button-prev">
                          <v-icon color="blue">mdi-arrow-left</v-icon>
                        </div>
                        <div class="swiper-button-next">
                          <v-icon color="blue">mdi-arrow-right</v-icon>
                        </div> -->
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="8">
                <v-card flat class="px-3" height="100%">
                  <v-card-text>
                    <div class="d-flex align-center">
                      <div>
                        <div
                          class="text-h5 font-weight-bold text-uppercase pt-4"
                        >
                          Customer Feedback
                        </div>
                        <div class="font-size-20 grey--text">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit,
                        </div>
                      </div>
                      <v-spacer />
                      <div class="d-flex align-center">
                        <div class="text-h5 font-weight-bold">
                          Total Responses
                        </div>
                        <div class="text-h3 font-weight-bold blue--text mt-1">
                          <v-icon size="40" color="orange"
                            >mdi-lightning-bolt</v-icon
                          >
                          43
                        </div>
                      </div>
                    </div>
                    <v-row class="mt-10">
                      <v-col cols="">
                        <div class="">
                          <div class="text-h6 fw-500 mb-2 grey--text">
                            <v-icon color="orange">mdi-star</v-icon>
                            <v-icon color="orange">mdi-star</v-icon>
                            <v-icon color="orange">mdi-star</v-icon>
                            <v-icon color="orange">mdi-star</v-icon>
                            <v-icon color="orange">mdi-star</v-icon>
                          </div>
                          <div
                            class="text-h3 font-weight-bold green--text mt-1"
                          >
                            <v-icon size="45" color="orange"
                              >mdi-emoticon-excited-outline</v-icon
                            >
                            30
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="">
                        <div class="">
                          <div class="text-h6 fw-500 mb-2 grey--text">
                            <v-icon color="orange">mdi-star</v-icon>
                            <v-icon color="orange">mdi-star</v-icon>
                            <v-icon color="orange">mdi-star</v-icon>
                            <v-icon color="orange">mdi-star</v-icon>
                          </div>
                          <div class="text-h3 font-weight-bold blue--text mt-1">
                            <v-icon size="45" color="orange"
                              >mdi-emoticon-happy-outline</v-icon
                            >
                            04
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="">
                        <div class="">
                          <div class="text-h6 fw-500 mb-2 grey--text">
                            <v-icon color="orange">mdi-star</v-icon>
                            <v-icon color="orange">mdi-star</v-icon>
                            <v-icon color="orange">mdi-star</v-icon>
                          </div>
                          <div class="text-h3 font-weight-bold blue--text mt-1">
                            <v-icon size="45" color="orange"
                              >mdi-emoticon-confused-outline</v-icon
                            >
                            04
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="">
                        <div class="">
                          <div class="text-h6 fw-500 mb-2 grey--text">
                            <v-icon color="orange">mdi-star</v-icon>
                            <v-icon color="orange">mdi-star</v-icon>
                          </div>
                          <div class="text-h3 font-weight-bold blue--text mt-1">
                            <v-icon size="45" color="orange"
                              >mdi-emoticon-neutral-outline</v-icon
                            >
                            04
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="">
                        <div class="">
                          <div class="text-h6 fw-500 mb-2 grey--text">
                            <v-icon color="orange">mdi-star</v-icon>
                          </div>
                          <div class="text-h3 font-weight-bold red--text mt-1">
                            <v-icon size="45" color="red"
                              >mdi-emoticon-angry</v-icon
                            >
                            01
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col md="12">
                <v-card flat class="px-3">
                  <v-card-text>
                    <div class="d-flex align-center">
                      <div class="text-h5 font-weight-bold text-uppercase pt-4">
                        New & Closed Jobs for 6 months
                      </div>
                      <v-spacer />
                      <v-btn
                        outlined
                        color="blue"
                        class="text-start rounded custom-menu-select py-1 btn-shadow-hover me-3"
                      >
                        <v-select
                          :items="durationList"
                          v-model="customerDuration"
                          hide-details
                          :disabled="issueCountLoadingNew"
                          item-color="cyan"
                          class="pt-0 mt-0 black--texr"
                          v-on:change="getIssueGraphNew"
                        >
                        </v-select>
                      </v-btn>
                    </div>
                    <div id="chart">
                      <apexchart
                        type="line"
                        height="350"
                        :options="newClosedJobschartOptions"
                        :series="newClosedJobsseries"
                      ></apexchart>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item :value="'tab-enquiry'">
            <EnquiryOverview />
          </v-tab-item>
          <v-tab-item :value="'tab-sales-contract'"
            ><SalesContractOverview />
          </v-tab-item>
          <v-tab-item :value="'tab-old'">
            <DashboardOld />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <template>
      <template v-if="customerDialog">
        <CustomerDialog
          :customerDialog="customerDialog"
          v-on:closeDialog="closeDialog"
          v-on:resetAll="resetAll"
          v-on:selectCustomer="selectCustomer"
          v-on:selectCustomerPerson="selectCustomerPerson"
          v-on:selectCustomerProperty="selectCustomerProperty"
          v-on:selectCustomerBilling="selectCustomerBilling"
        ></CustomerDialog>
      </template>
    </template>
  </v-container>
</template>
<script>
const colorPalette = ["#00D8B6", "#008FFB", "#FEB019", "#FF4560", "#775DD0"];
import { GET, QUERY } from "@/core/services/store/request.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import EnquiryOverview from "@/view/pages/EnquiryOverview";
import SalesContractOverview from "@/view/pages/SalesContractOverview";
import DashboardOld from "@/view/pages/DashboardOld.vue";
import Swiper from "swiper";
export default {
  name: "dashboard",
  data() {
    return {
      slidesPerView: 1,
      spaceBetween: 10,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      categoryList: [
        { text: "Open", value: "open" },
        { text: "Closed", value: "closed" },
        { text: "Overdue", value: "overdue" },
      ],
      isCategory: "open",
      tabs: [
        {
          title: "Jobs",
          icon: "mdi-briefcase",
          key: "jobs",
        },
        {
          title: "Enquiry",
          icon: "mdi-account-question-outline",
          key: "enquiry",
        },
        {
          title: "Sales & Contract",
          icon: "mdi-sale",
          key: "sales-contract",
        },
        {
          title: "Old Dashboard",
          icon: "mdi-briefcase",
          key: "old",
        },
      ],
      dashboardTab: "tab-jobs",
      jobSummaryData: [
        {
          title: "Last Month Jobs",
          value: 670,
          color: "cyan",
          textColor: "cyan--text",
          iconColor: "cyan",
          icon: "mdi-briefcase-account-outline",
        },
        {
          title: "Current Month Jobs",
          value: 36,
          color: "green",
          textColor: "green--text",
          iconColor: "green",
          icon: "mdi-briefcase-account-outline",
        },
        {
          title: "Total Engineers",
          value: 16,
          color: "blue",
          textColor: "blue--text",
          iconColor: "blue",
          icon: "mdi-account-hard-hat",
        },
        {
          title: "Punched in Engineers",
          value: 14,
          color: "orange",
          textColor: "orange--text",
          iconColor: "orange",
          icon: "mdi-account-hard-hat",
        },
        {
          title: "Punched Out Engineers",
          value: 2,
          color: "red",
          textColor: "red--text",
          iconColor: "red",
          icon: "mdi-account-hard-hat",
        },
      ],
      jobCategoryseries: [
        {
          data: [30, 8, 18, 9],
        },
      ],
      jobCategorychartOptions: {
        chart: {
          type: "bar",
          height: 220,
        },

        title: {
          text: "Record Count",
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: ["Service", "Installation", "Call Out", "Training"],
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
        },
        yaxis: {
          reversed: false,
          axisTicks: {
            show: true,
          },
          // title: {
          //   text: "Jobs Category",
          // },
        },
      },

      jobStatusseries: [43, 12, 1, 4, 1],
      jobStatuschartOptions: {
        chart: {
          width: 425,
          type: "donut",
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
          },
        },
        labels: [
          "Open",
          "In-Progress",
          "Hold & Stay Assign",
          "Completed",
          "Cancelled",
        ],
        dataLabels: {
          enabled: true,
        },
        fill: {
          type: "gradient",
        },
        legend: {
          formatter: function (val, opts) {
            return val + " - " + opts.w.globals.series[opts.seriesIndex];
          },
        },
        title: {
          text: "Record Count",
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      jobByEngineerseries: [
        {
          name: "Serivce",
          data: [44, 55, 41, 37, 22, 43, 21, 23, 12, 15],
        },
        {
          name: "Installtion",
          data: [53, 32, 33, 52, 13, 43, 32, 12, 15, 46],
        },
        {
          name: "Call Out",
          data: [12, 17, 11, 9, 15, 11, 20, 12, 15, 46],
        },
        {
          name: "Training",
          data: [9, 7, 5, 8, 6, 9, 4, 12, 15, 16],
        },
      ],
      jobByEngineerchartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: [
            "Michaela",
            "Rana",
            "Sally",
            "Ajai",
            "George",
            "Terrence",
            "Leon",
            "Leowon",
            "Chelsea",
            "Accounts Bthrust",
          ],
          labels: {
            formatter: function (val) {
              return val + "GB";
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "GB";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
      jobCategoryByEngineerseries: [
        {
          name: "Open",
          data: [44, 55, 41, 37, 22, 43, 21, 12, 15, 46],
        },
        {
          name: "Completed",
          data: [53, 32, 33, 52, 13, 43, 32, 12, 15, 46],
        },
        {
          name: "Hold & Stay Assign",
          data: [12, 17, 11, 9, 15, 11, 20, 12, 15, 46],
        },
        {
          name: "Cancelled",
          data: [9, 7, 5, 8, 6, 9, 4, 12, 15, 46],
        },
        {
          name: "In-Progress",
          data: [9, 7, 5, 8, 6, 9, 4, 12, 15, 46],
        },
      ],
      jobCategoryByEngineerchartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              total: {
                enabled: true,
                offsetX: 0,
                style: {
                  fontSize: "13px",
                  fontWeight: 900,
                },
              },
            },
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: [
            "Michaela",
            "Rana",
            "Sally",
            "Ajai",
            "George",
            "Terrence",
            "Leon",
            "Leowon",
            "Chelsea",
            "Accounts Bthrust",
          ],
          labels: {
            formatter: function (val) {
              return val + "GB";
            },
          },
        },
        yaxis: {
          title: {
            text: undefined,
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val + "GB";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },

      newClosedJobsseries: [
        {
          name: "New Jobs",
          data: [28, 29, 33, 36, 32, 32],
        },
        {
          name: "Closed Jobs",
          data: [12, 11, 14, 18, 17, 13],
        },
      ],
      newClosedJobschartOptions: {
        chart: {
          height: 350,
          type: "line",
          dropShadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2,
          },
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        colors: ["#4CAF50", "#F44336"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "smooth",
        },
        title: {
          text: "Average New & Closed Jobs",
          align: "left",
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 1,
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
          title: {
            text: "Month",
          },
        },
        yaxis: {
          title: {
            text: "Jobs",
          },
          min: 5,
          max: 40,
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
      },

      customer: 0,
      customerPerson: 0,
      customerProperty: 0,
      customerBilling: 0,
      customerDialog: false,
      customerPersonDialog: false,
      customerPropertyDialog: false,
      transactionType: null,
      issueDCountLoading: false,
      overdue_visits: false,
      overdue_deliveies: false,
      prioritySeries: [],
      priorityDeliverySeries: [],
      issueCountLoadingNew: false,
      issueSeriesNew: [],

      issueChartoptionsNew: {
        dataLabels: {
          enabled: false,
        },
        colors: colorPalette,
        labels: [],
        legend: {
          position: "left",
        },
      },
      priorityChartoptions: {
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          followCursor: true,
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
        },
      },
      priorityDeliveryChartoptions: {
        stroke: {
          curve: "straight",
        },
        xaxis: {
          categories: [],
        },
        tooltip: {
          followCursor: true,
        },
        fill: {
          opacity: 1,
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
        },
      },
      issueSeries: [],
      deliverySeries: [],

      issueChartoptions: {
        dataLabels: {
          enabled: false,
        },
        colors: [],
        labels: [],
        legend: {
          position: "left",
        },
      },
      deliveryChartoptions: {
        dataLabels: {
          enabled: false,
        },
        colors: [],
        labels: [],
        legend: {
          position: "left",
        },
      },
      visitPriorityArr: [],
      deliveryPriorityArr: [],
      issueDuration: "this_month",
      issueCountLoading: false,
      priorityDuration: "this_month",
      priorityDeliveryDuration: "this_month",
      priorityCountLoading: false,
      priorityCountDeliveryLoading: false,
      countLoading: true,
      customerCountLoading: true,
      jobCountLoading: true,
      quotationCountLoading: true,
      engcustomerDuration: "this_month",
      engineerdurationList: [
        { text: "This Month", value: "this_month" },
        { text: "Last Month", value: "last_month" },
      ],
      durationList: [
        { text: "This Week", value: "this_week" },
        { text: "This Month", value: "this_month" },
        { text: "Last 3 Month", value: "last-3month" },
        { text: "Last 6 Month", value: "last-6month" },
      ],
      customerDuration: "this_month",
      jobDuration: "this_month",
      quotationDuration: "this_month",
      bugCount: 0,
      customerCount: {
        count: 0,
        all_customers: 0,
        active_customers: 0,
        percentage: 0,
      },
      quotationCount: {
        /*      count: 0,
        percentage: 0,
        average_revenue: 0, */
      },
      jobsCount: {
        count: 0,
        all_jobs: 0,
        recurring_jobs: 0,
        percentage: 0,
      },
      jobCount: {
        total_jobs: 0,
        total_open_jobs: 0,
        total_closed_jobs: 0,
        total_cancelled_jobs: 0,
      },
      visitCount: {
        total_visits: 0,
        total_open_visits: 0,
        total_overdue_visits: 0,
        total_today_visits: 0,
        total_tomorrow_visits: 0,
        total_this_week_visits: 0,
        total_upcomming_visits: 0,
        total_inprogress_visits: 0,
        total_hold_visits: 0,
        total_completed_visits: 0,
        total_cancelled_visits: 0,
      },

      contractCount: {
        active_contracts: 0,
        expired_contracts: 0,
        cancelled_contracts: 0,
        draft_contracts: 0,
        about_to_expired_contracts: 0,
        rejected_contracts: 0,
        total_contracts: 0,
      },

      projectCount: {
        cancelled_projects: 0,
        finished_projects: 0,
        in_progress_projects: 0,
        not_started_projects: 0,
        on_hold_projects: 0,
        total_projects: 0,
      },
      deliveryCount: {
        total_assigned_delivery: 0,
        total_delivered: 0,
        total_delivery: 0,
        total_failed_delivey: 0,
        total_inprogress_delivery: 0,
        total_is_accepted: 0,
        total_new_delivery: 0,
        total_overdue_delivery: 0,
        total_partial_delivery: 0,
        total_this_week_delivery: 0,
        total_today_delivery: 0,
        total_tomorrow_delivery: 0,
        total_unassigned_delivery: 0,
        total_upcomming_delivery: 0,
      },
    };
  },
  methods: {
    getBgColor(color) {
      switch (color) {
        case "cyan":
          return "#00bcd4";
        case "green":
          return "#4CAF50";
        case "red":
          return "#F44336";
        case "blue":
          return "#ff9800";
        case "orange":
          return "#ff9800";
        default:
          return "white";
      }
    },
    updateChart() {
      const max = 90;
      const min = 20;
      const newData = this.series[0].data.map(() => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      });
      // In the same way, update the series option
      this.series = [
        {
          data: newData,
        },
      ];
    },
    getIssueGraphNew() {
      const _this = this;
      _this
        .getDashboardIssueCountNew()
        .then(({ issueSeries, labels }) => {
          if (labels) {
            _this.issueChartoptionsNew.labels = labels;
          }
          if (issueSeries) {
            _this.issueSeriesNew = issueSeries;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.issueCountLoadingNew = false;
        });
    },
    getDashboardIssueCountNew() {
      const _this = this;
      _this.issueCountLoadingNew = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-issue-graph",
              data: {
                duration: _this.issueSeriesNew,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    priorityText(row) {
      if (row.priority == 1) {
        return "High";
      }
      if (row.priority == 2) {
        return "Medium";
      }
      if (row.priority == 3) {
        return "Low";
      }
      if (row.priority == 0) {
        return "None";
      }
      return null;
    },
    priorityColor(row) {
      if (row.priority == 1) {
        return "svg-icon-danger";
      }
      if (row.priority == 2) {
        return "svg-icon-warning";
      }
      if (row.priority == 3) {
        return "svg-icon-success";
      }
      if (row.priority == 0) {
        return "cyan";
      }
      return null;
    },
    priorityBgColor(row) {
      if (row.priority == 1) {
        return "#f64e60";
      }
      if (row.priority == 2) {
        return "#ffa800";
      }
      if (row.priority == 3) {
        return "#1bc5bd";
      }
      /* if (row.priority == 0) {
        return "cyan";
      } */
      return null;
    },
    checkExixsPriority(value) {
      const priorityExists = this.visitPriorityArr.some(
        (item) => item.priority == value
      );
      return priorityExists;
    },

    createTransaction(type) {
      this.transactionType = type;
      this.customerDialog = true;
    },
    resetAll() {
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.customerDialog = false;
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
    },
    selectCustomer(param) {
      this.customer = param;
      this.closeDialog();
      this.checkTransactionType();
      if (!this.customerPerson || this.customerPerson <= 0) {
        this.customerPersonDialog = true;
      } else {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkTransactionType();
      if (
        (!this.customerProperty || this.customerProperty <= 0) &&
        this.transactionType != "quotation"
      ) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkTransactionType();
    },
    selectCustomerBilling(param) {
      this.customerBilling = param;
      this.closeDialog();
      this.checkTransactionType();
    },
    checkTransactionType() {
      const _this = this;
      if (_this.transactionType === "quotation") {
        if (
          _this.customer > 0 &&
          _this.customerPerson > 0 &&
          _this.customerBilling > 0
        ) {
          _this.$router.push(
            _this.getDefaultRoute("quotation.create", {
              query: {
                customer: _this.customer,
                contact_person: _this.customerPerson,
                billing: _this.customerBilling,
              },
            })
          );
        }
      } else {
        if (
          _this.customer > 0 &&
          _this.customerPerson > 0 &&
          _this.customerProperty > 0
        ) {
          _this.$router.push(
            _this.getDefaultRoute(_this.transactionType + ".create", {
              query: {
                customer: _this.customer,
                contact_person: _this.customerPerson,
                property: _this.customerProperty,
              },
            })
          );
        }
      }
    },
    getDashboardCount() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, {
              url: "dashboard-count",
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardPriorityMainCount() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, {
              url: "dashboard-priority-count",
            })
            .then(({ data }) => {
              this.visitPriorityArr = data.visitPriority;
              this.deliveryPriorityArr = data.deliveryPriority;
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardCustomerCount() {
      const _this = this;
      _this.customerCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-customer",
              data: {
                duration: _this.customerDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardJobCount() {
      const _this = this;
      _this.jobCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-job",
              data: {
                duration: _this.jobDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardQuotationCount() {
      const _this = this;
      _this.quotationCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-quotation",
              data: {
                duration: _this.quotationDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },

    getGrafcolor() {
      this.lodash.forEach(this.deliveryPriorityArr, (row) => {
        console.log(row, "rowrow");
      });
    },
    getDashboardDIssueCount() {
      const _this = this;
      _this.issueDCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-priority-delivery-piegraph",
              data: {
                duration: _this.issueDuration,
              },
            })
            .then(({ data }) => {
              /*  console.log(data,'data') */
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDashboardIssueCount() {
      const _this = this;
      _this.issueCountLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "dashboard-priority-visit-graph",
              data: {
                duration: _this.issueDuration,
              },
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getCustomerCount() {
      const _this = this;
      _this
        .getDashboardCustomerCount()
        .then(({ customer }) => {
          if (customer) {
            _this.customerCount = customer;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.customerCountLoading = false;
        });
    },
    getJobsCount() {
      const _this = this;
      _this
        .getDashboardJobCount()
        .then(({ job }) => {
          if (job) {
            _this.jobsCount = job;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.jobCountLoading = false;
        });
    },
    getQuotationCount() {
      const _this = this;
      _this
        .getDashboardQuotationCount()
        .then(({ quotation }) => {
          if (quotation) {
            _this.quotationCount = quotation;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.quotationCountLoading = false;
        });
    },
    getIssueGraph() {
      const _this = this;
      _this
        .getDashboardIssueCount()
        .then(({ issueSeries, labels, issueSeriesColor }) => {
          if (labels) {
            _this.issueChartoptions.labels = labels;
          }
          if (issueSeries) {
            _this.issueSeries = issueSeries;
          }
          if (issueSeriesColor) {
            _this.issueChartoptions.colors = issueSeriesColor;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.issueCountLoading = false;
        });
    },
    getDeliveryIssueGraph() {
      const _this = this;
      _this
        .getDashboardDIssueCount()
        .then(({ deliverySeries, labels, issueSeriesColor }) => {
          if (labels) {
            _this.deliveryChartoptions.labels = labels;
          }
          if (deliverySeries) {
            _this.deliverySeries = deliverySeries;
          }
          if (issueSeriesColor) {
            _this.deliveryChartoptions.colors = issueSeriesColor;
          }
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.issueDCountLoading = false;
        });
    },
  },
  components: {
    CustomerDialog,
    EnquiryOverview,
    SalesContractOverview,
    DashboardOld,
  },
  mounted() {
    const _this = this;
    _this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Dashboard",
      },
    ]);
    _this.getCustomerCount();
    _this.getJobsCount();
    _this.getQuotationCount();

    _this.getDashboardPriorityMainCount();
    _this.getIssueGraph();
    _this.getIssueGraphNew();
    _this.getDeliveryIssueGraph();

    _this
      .getDashboardCount()
      .then(({ job, visit, bug, project, delivery, contract, quotation }) => {
        if (job) {
          _this.jobCount = job;
        }
        if (visit) {
          _this.visitCount = visit;
        }
        if (bug) {
          _this.bugCount = bug;
        }
        if (project) {
          _this.projectCount = project;
        }
        if (delivery) {
          _this.deliveryCount = delivery;
        }
        if (contract) {
          _this.contractCount = contract;
        }
        if (quotation) {
          _this.quotationCount = quotation;
        }
      })
      .catch((error) => {
        _this.logError(error);
      })
      .finally(() => {
        _this.countLoading = false;
      });
    /*
inspiration
https://cz.pinterest.com/pin/830703093790696716/
*/

    new Swiper(".swiper", {
      effect: "coverflow",
      grabCursor: true,
      centeredSlides: true,
      coverflowEffect: {
        rotate: 0,
        stretch: 0,
        depth: 100,
        modifier: 4,
        slideShadows: true,
      },
      loop: true,
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      keyboard: {
        enabled: true,
      },
      mousewheel: {
        thresholdDelta: 70,
      },
      breakpoints: {
        560: {
          slidesPerView: 23,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 3,
        },
      },
    });
  },
};
</script>

<style scoped src="@/assets/sass/dashboard.scss" lang="scss">
.span.apexcharts-legend-text {
  font-weight: 500 !important;
  font-size: 15px !important;
}
</style>
